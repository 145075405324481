import React, { useState, useRef, useEffect } from "react"
import classnames from "classnames";
import Button from '../Button'
import './index.module.less'

import IconStart from 'assets/images/icons/icon-start.svg'

export interface VideoSource {
  src: string;
  type: string;
}

export interface VideoProps {
  className?: string;
  controls?: boolean;
  source?: VideoSource[];
  url?: string;
  poster?: string;
  supportMessage?: string;
  autoPlay?: boolean;
  playStatus?: boolean;
  onPlayCallback?: (value?: HTMLVideoElement) => void
  onPauseCallback?: (value?: HTMLVideoElement) => void
}

const Video: React.FC<VideoProps> = (props) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const {
    className,
    controls,
    playStatus,
    url,
    source = [{
      src: url,
      type: "video/mp4",
    }],
    poster,
    autoPlay,
    supportMessage = "Sorry, your browser doesn't support embedded videos.",
    onPlayCallback = () => {},
    onPauseCallback = () => {},
  } = props;

  useEffect(() => {
    if(!playStatus) {
      videoRef?.current?.pause();
    } 
  }, [playStatus]);

  const handleClickStart = () => {
    if(videoRef?.current?.paused) {
      videoRef?.current?.play();
    }
  }

  const onPlay = () => {
    setIsPlaying(true);
    onPlayCallback(videoRef?.current as HTMLVideoElement);
  }

  const onPause = () => {
    setIsPlaying(false);
    onPauseCallback(videoRef?.current as HTMLVideoElement);
  }

  const onSeeked = () => {
    if(videoRef?.current?.paused) {
      videoRef?.current?.play();
    }
  }

  return (
    <div className={classnames("sd-video", className)}>
      <video 
        ref={videoRef}
        autoPlay={autoPlay}
        controls={controls || isPlaying}
        poster={poster}
        onPlay={onPlay}
        onPause={onPause}
        onSeeked={onSeeked}
      >
        {source?.map((item, index) => <source src={item.src} type={item.type} key={index}></source>)}
        <p>{supportMessage}</p>
      </video>
      {!isPlaying && <Button btnType="text" className="sd-video--start-button" onClick={handleClickStart}>
        <img src={IconStart} alt="iconstart" />
      </Button>}
    </div>
  );
}

export default Video;